html {
  box-sizing: border-box;
  line-height: 1.6;
  letter-spacing: .06em;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter UI', -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI",  Helvetica, Arial, sans-serif;
  font-display: auto;
  font-size: 1rem;
  line-height: 1.54;
  background-color: $light-background;
  color: $light-color;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "liga", "tnum", "case", "calt", "zero", "ss01", "locl";
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;

  display: flex;
  min-height: 100vh;
  flex-direction: column;

  @media #{$media-size-phone} {
    font-size: 1rem;
  }

  &.dark-theme {
    background-color: $dark-background;
    color: $dark-color;
  }
}

h2, h3, h4, h5, h6 {
  display: flex;
  align-items: center;
  line-height: 1.3;
}

h1 {
  font-size: 2.625rem;
}

h2 {
  font-size: 1.625rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

@media #{$media-size-phone} {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  h3 {
    font-size: 1.15rem;
  }

  h4 {
    font-size: 1.125rem;
  }
}

a {
  color: inherit;
}

img {
  display: block;
  max-width: 100%;

  &.left {
    margin-right: auto;
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  &.right {
    margin-left: auto;
  }
  &.circle {
    border-radius: 50%;
    max-width: 25%;
    margin: auto;
  }
}

figure {
  display: table;
  max-width: 100%;
  margin: 25px 0;

  &.left {
    margin-right: auto;
  }

  &.left-floated {
    margin-right: auto;
    float: left;
    img {
      margin: 20px 20px 20px 0;
    }
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  &.right {
    margin-left: auto;
  }

  &.right-floated {
    margin-left: auto;
    float: right;
    img {
      margin: 20px 0 20px 20px;
    }
  }

  &.rounded {
    img {
      border-radius: 50%;
    }
  }

  figcaption {
    font-size: 14px;
    margin-top: 5px;
    opacity: .8;

    &.left {
      text-align: left;
    }

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }
  }
}

code {
  font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
  font-display: auto;
  font-feature-settings: normal;
  background: $light-background-secondary;
  padding: 1px 6px;
  margin: 0 2px;
  border-radius: 5px;
  font-size: .95rem;

  .dark-theme & {
    background: $dark-background-secondary;
  }
}

pre {
  background: #212020;
  padding: 10px 10px 10px 20px;
  border-radius: 8px;
  font-size: .95rem;
  overflow: auto;

  @media #{$media-size-phone} {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  code {
    background: none !important;
    color: #ccc;
    margin: 0;
    padding: 0;
    font-size: inherit;

    .dark-theme & {
      color: inherit;
    }
  }
}

blockquote {
  border-left: 2px solid;
  margin: 40px;
  padding: 10px 20px;

  @media #{$media-size-phone} {
    margin: 10px;
    padding: 10px;
  }

  &:before {
    content: '”';
    font-family: Georgia, serif;
    font-display: auto;
    font-size: 3.875rem;
    position: absolute;
    left: -40px;
    top: -20px;
  }

  p:first-of-type {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

ul, ol {
  margin-left: 40px;
  padding: 0;

  @media #{$media-size-phone} {
    margin-left: 20px;
  }
}

ol ol {
  list-style-type: lower-alpha;
}

.container {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  align-items: center;
  justify-content: center;
  margin: 0;

  @media #{$media-size-phone} {
    margin-top: 2em;
  }
}

hr {
  width: 100%;
  border: none;
  background: $light-border-color;
  height: 1px;

  .dark-theme & {
    background: $dark-border-color;
  }
}

.hidden {
  display: none;
}

.hide-on-phone {
  @media #{$media-size-phone} {
    display: none;
  }
}

.hide-on-tablet {
  @media #{$media-size-tablet} {
    display: none;
  }
}

// Accessibility
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	clip-path: none;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
    width: auto;
    top: 5px;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	z-index: 100000;
}
